<template>
  <ParentRouter />
</template>

<script>
import ParentRouter from '@/components/ParentRouter'
export default {
  components: {
    ParentRouter
  }
}
</script>
